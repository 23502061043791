<template>
  <div class="creator-step">
    <div class="creator-step-title">{{ $locale["verify_result_title"] }}</div>
    <div v-if="verifyOnProgress || isStep3Complete">
      <div class="creator-step-content">
        <VerifyUserStatus />
      </div>
      <div class="creator-step-options">
        <GradientButton :label="$locale['finish']" to="/" />
      </div>
    </div>
    <div v-else>
      <div class="creator-step-content">
        {{ $locale.words["plaease_complete"] + " " + $locale.words["step"] + " 3" }}
      </div>
      <div class="creator-step-options">
        <GradientButton :label="$locale['words']['go_back']" to="/user/creator?apply=step3" icon="arrowLeft" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    VerifyUserStatus: () => import("../verify-user/VerifyUserStatus.vue"),
  },
  computed: {
    isCompleteProfile: function() {
      return this.$user.user && this.$user.name && this.$user.describe && this.$user.country && this.$user.birthday;
    },
    isStep3Complete: function() {
      return this.$store.state.creator.apply.step3;
    },
    verifyOnProgress: function() {
      if (this.$user.identity?.id_front && this.$user.identity?.id_back && this.$user.identity?.face_id) {
        return true;
      }
      return false;
    },
  },
};
</script>
